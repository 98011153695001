import { B as BUNKER_STATUS_TEXTS, a as BUNKER_STATUS_COLORS } from "./bunker.status.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { RECEIPT_PERMISSION, TIP_PERMISSION, REVERSE_PERMISSION, GROWERS_VBINSPROCESSING } from "@kraftheinz/common";
var render$7 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_vm.recordItem && _vm.visibleModal ? _c("a-modal", {
          attrs: {
            "destroy-on-close": "",
            "title": "Receipt VBin",
            "visible": _vm.visibleModal,
            "centered": ""
          },
          on: {
            "ok": _vm.submitModal,
            "cancel": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submitModal);
                  }
                }
              }, [_vm._v("Save & Print Docket")])];
            },
            proxy: true
          }], null, true)
        }, [_c("h4", {
          staticClass: "h4 mb-4"
        }, [_vm._v(" " + _vm._s("BUNKER " + _vm.recordItem.bunkerCode) + " ")]), _c("a-row", {
          attrs: {
            "gutter": [24]
          }
        }, [_c("a-col", {
          attrs: {
            "xs": 24
          }
        }, [_c("div", {
          staticClass: "gross-weight"
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Gross Weight",
            "suffix": "KG",
            "rules": "required"
          },
          on: {
            "change": _vm.onWeightObsChange
          }
        })], 1)]), _c("a-col", {
          attrs: {
            "xs": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Truck",
            "data-source": _vm.bunkerTrucks,
            "source": "id",
            "source-label": "description",
            "rules": "required"
          },
          on: {
            "change": _vm.onTrucksChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "xs": 12
          }
        }, [_c("label", [_vm._v("VBins Truck Weight")]), _c("a-input", {
          attrs: {
            "disabled": true,
            "suffix": "KG",
            "span": 24
          },
          model: {
            value: _vm.tareValue,
            callback: function($$v) {
              _vm.tareValue = $$v;
            },
            expression: "tareValue"
          }
        })], 1)], 1)], 1) : _vm._e()];
      }
    }])
  });
};
var staticRenderFns$7 = [];
var ReceiptBunkerVbins_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$7 = {
  name: "ReceiptBunkerVbins",
  inject: ["apiUrl", "resourceProps"],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    const [
      receiptBunkerProps,
      vbinsTrucksProps,
      vbinsPrintProps
    ] = this.resourceProps;
    return {
      receiptBunkerProps,
      vbinsTrucksProps,
      vbinsPrintProps,
      customUrl: `${this.apiUrl}/grower`,
      tareValue: 0,
      weightObserved: 0,
      writeoffReason: "",
      visibleModal: false,
      recordItem: null
    };
  },
  computed: {
    bunkerTrucks() {
      return this.vbinsTrucksProps.crud.getList();
    }
  },
  watch: {
    tareValue(newVal) {
      this.tareValue = newVal;
    },
    visible(newVal) {
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
    }
  },
  async mounted() {
    this.vbinsTrucksProps.crud.fetchList(`${this.customUrl}/common/trucks?truckTypeId=3`, void 0, false);
  },
  methods: {
    storeValue(value, resourceKey, isPrint = null) {
      if (isPrint) {
        this.vbinsPrintProps.crud.updateEntity("createForm", {
          resourceKey,
          value
        });
        return;
      }
      this.receiptBunkerProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
      return;
    },
    onTrucksChange(e) {
      this.tareValue = this.bunkerTrucks.filter((v) => v.id === e)[0].tare;
      this.storeValue(e, "truckId");
    },
    onWeightObsChange(e) {
      if (e !== "") {
        let isNumber = /^\d+$/.test(e);
        if (!isNumber)
          e = this.weightObserved;
        else
          this.weightObserved = parseInt(e);
      } else
        this.weightObserved = e;
      this.storeValue(this.weightObserved, "weight");
    },
    submitModal() {
      this.receiptBunkerProps.crud.submitEntity("replace", `${this.customUrl}/bunkers/vbins/${this.recordItem.id}/receipt`).then((res) => {
        this.printWeight(res.data);
        this.$emit("updated", { type: "receipt", visible: false });
      });
    },
    printWeight(data) {
      this.storeValue("VBinDocket", "report", true);
      this.storeValue("grower", "reportType", true);
      this.storeValue({ TransactionID: data.bunkerTransId }, "reportParams", true);
      this.vbinsPrintProps.crud.submitEntity("create", `${this.apiUrl}/identities/reports`).then((res) => window.open(res.data.reportViewerUri, "_blank"));
    },
    cancel() {
      this.$emit("update:visible", false);
    }
  }
};
const __cssModules$7 = {};
var __component__$7 = /* @__PURE__ */ normalizeComponent(__vue2_script$7, render$7, staticRenderFns$7, false, __vue2_injectStyles$7, "16355198", null, null);
function __vue2_injectStyles$7(context) {
  for (let o in __cssModules$7) {
    this[o] = __cssModules$7[o];
  }
}
var ReceiptBunkerVbins$1 = /* @__PURE__ */ function() {
  return __component__$7.exports;
}();
var render$6 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "writeoff-bunker-container"
  }, [_c("resource", {
    attrs: {
      "name": ["grower.bunker.vbins.receipt", "grower.bunker.vbins.trucks", "grower.bunker.vbins.print"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/watties-grower/bunker-vbins", ""]
    }
  }, [_c("receipt-bunker-vbins", {
    attrs: {
      "visible": _vm.visibleModal,
      "record": _vm.recordItem
    },
    on: {
      "update:visible": [function($event) {
        _vm.visibleModal = $event;
      }, _vm.updateVisible],
      "update:record": function($event) {
        _vm.recordItem = $event;
      },
      "updated": function($event) {
        return _vm.updated($event);
      }
    }
  })], 1), _c("router-view")], 1);
};
var staticRenderFns$6 = [];
const apiUrl$3 = "#{VUE_APP_API_URL}#";
const __vue2_script$6 = {
  components: {
    ReceiptBunkerVbins: ReceiptBunkerVbins$1
  },
  props: {
    record: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$3,
      visibleModal: this.visible,
      recordItem: this.record
    };
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
    }
  },
  methods: {
    updated(e) {
      this.$emit("updated", e);
    },
    updateVisible(e) {
      this.$emit("update:visible", e);
    }
  }
};
const __cssModules$6 = {};
var __component__$6 = /* @__PURE__ */ normalizeComponent(__vue2_script$6, render$6, staticRenderFns$6, false, __vue2_injectStyles$6, null, null, null);
function __vue2_injectStyles$6(context) {
  for (let o in __cssModules$6) {
    this[o] = __cssModules$6[o];
  }
}
var ReceiptBunkerVbins = /* @__PURE__ */ function() {
  return __component__$6.exports;
}();
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _vm.recordItem && _vm.visibleModal ? _c("a-modal", {
    attrs: {
      "destroy-on-close": "",
      "confirm-loading": _vm.loading,
      "title": "Tip VBin",
      "visible": _vm.visibleModal,
      "width": 832,
      "bodyStyle": {
        maxHeight: "668px",
        overflow: "auto"
      },
      "centered": ""
    },
    on: {
      "cancel": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function() {
        return [_c("a-button", {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v("Cancel")]), _c("a-button", {
          attrs: {
            "type": "primary",
            "ghost": "",
            "disabled": _vm.selectedRowKeys.length === 0
          },
          on: {
            "click": _vm.printItem
          }
        }, [_vm._v("Reprint (" + _vm._s(_vm.selectedRowKeys.length) + ")")]), _c("a-button", {
          attrs: {
            "type": "primary",
            "disabled": _vm.selectedRowKeys.length === 0
          },
          on: {
            "click": _vm.tipsItem
          }
        }, [_vm._v("Tip (" + _vm._s(_vm.selectedRowKeys.length) + ")")])];
      },
      proxy: true
    }], null, false, 3764943811)
  }, [_c("h4", {
    staticClass: "h4 mb-4"
  }, [_vm._v(_vm._s("BUNKER " + _vm.recordItem.bunkerCode))]), _c("list-composer", {
    attrs: {
      "has-active": false,
      "has-filter-inactive": false,
      "has-action": false,
      "has-advanced-search": false,
      "has-filter": false,
      "hide-pagination": true,
      "custom-url": _vm.getListUrl,
      "custom-padding-table": "p-0",
      "custom-margin-filter": "mb-0",
      "selected-row-keys": _vm.selectedRowKeys,
      "on-select-change": _vm.onSelectChange
    }
  }, [_c("text-field", {
    attrs: {
      "data-index": "id",
      "title": "Transaction ID"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "bunkerHistoryId",
      "title": "History ID"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "vBin",
      "title": "VBin"
    }
  })], 1)], 1) : _vm._e();
};
var staticRenderFns$5 = [];
var TipsBunkerVbins_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$5 = {
  name: "TipsBunkerVbins",
  inject: ["apiUrl", "crud"],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      customUrl: `${this.apiUrl}/grower`,
      getListUrl: "",
      visibleModal: false,
      recordItem: this.record,
      selectedRowKeys: [],
      loading: false,
      cacheRecord: {}
    };
  },
  watch: {
    visible(newVal) {
      this.selectedRowKeys = [];
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.cacheRecord = this.recordItem;
      this.recordItem = newVal;
      this.getListUrl = "";
      this.getListUrl = `${this.apiUrl}/grower/bunkers/vbins/${this.recordItem.id}/tips`;
      this.fetchList(this.getListUrl);
    }
  },
  methods: {
    fetchList(customUrl) {
      this.crud.fetchList(customUrl, void 0, false);
    },
    storeValue(value, resourceKey) {
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
      return;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    tipsItem() {
      this.loading = true;
      this.storeValue(this.selectedRowKeys, "listIds");
      this.crud.submitEntity("replace", `${this.getListUrl}`).then(() => {
        this.loading = false;
        this.$emit("updated", { type: "tip", visible: false });
        this.recordItem = null;
      });
    },
    printItem() {
      let data = {
        type: "VBinDocket",
        parameters: { TransID: this.recordItem.id }
      };
      this.loading = true;
      this.axios.post(`${this.customUrl}/reports/preview`, data).then((res) => {
        window.open(res.data.reportViewerUri, "_blank");
        this.$emit("updated", { type: "tip", visible: false });
      }, (err) => {
        this.$notification["error"]({
          message: err.message || "Errors!"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    cancel() {
      this.$emit("update:visible", false);
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, "28b10742", null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var TipsBunkerVbins$1 = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "vbins-tips-container"
  }, [_c("resource", {
    attrs: {
      "name": "grower.vbins.tips",
      "api-url": _vm.apiUrl
    }
  }, [_c("tips-bunker-vbins", {
    attrs: {
      "visible": _vm.visibleModal,
      "record": _vm.recordItem
    },
    on: {
      "update:visible": [function($event) {
        _vm.visibleModal = $event;
      }, _vm.updateVisible],
      "update:record": function($event) {
        _vm.recordItem = $event;
      },
      "updated": function($event) {
        return _vm.updated($event);
      }
    }
  })], 1), _c("router-view")], 1);
};
var staticRenderFns$4 = [];
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  components: {
    TipsBunkerVbins: TipsBunkerVbins$1
  },
  props: {
    record: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$2,
      visibleModal: this.visible,
      recordItem: this.record
    };
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
    }
  },
  methods: {
    updated(e) {
      this.$emit("updated", e);
    },
    updateVisible(e) {
      this.$emit("update:visible", e);
    }
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var TipsBunkerVbins = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _vm.recordItem && _vm.visibleModal ? _c("a-modal", {
    attrs: {
      "destroy-on-close": "",
      "confirm-loading": _vm.loading,
      "title": "Reverse VBin",
      "visible": _vm.visibleModal,
      "width": 832,
      "bodyStyle": {
        maxHeight: "668px",
        overflow: "auto"
      },
      "centered": ""
    },
    on: {
      "cancel": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function() {
        return [_c("a-button", {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v("Cancel")]), _c("a-button", {
          attrs: {
            "type": "primary",
            "disabled": _vm.selectedRowKeys.length === 0
          },
          on: {
            "click": _vm.reverseItem
          }
        }, [_vm._v("Reverse (" + _vm._s(_vm.selectedRowKeys.length) + ")")])];
      },
      proxy: true
    }], null, false, 3611977872)
  }, [_c("h4", {
    staticClass: "h4 mb-4"
  }, [_vm._v(_vm._s("BUNKER " + _vm.recordItem.bunkerCode))]), _c("list-composer", {
    attrs: {
      "has-active": false,
      "has-filter-inactive": false,
      "has-action": false,
      "has-advanced-search": false,
      "has-filter": false,
      "hide-pagination": true,
      "custom-url": _vm.getListUrl,
      "custom-padding-table": "p-0",
      "custom-margin-filter": "mb-0",
      "selected-row-keys": _vm.selectedRowKeys,
      "on-select-change": _vm.onSelectChange
    }
  }, [_c("text-field", {
    attrs: {
      "data-index": "id",
      "title": "Transaction ID"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "vBin",
      "title": "VBin"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "weight",
      "title": "Weight (KG)"
    }
  })], 1)], 1) : _vm._e();
};
var staticRenderFns$3 = [];
var ReverseBunkerVbins_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$3 = {
  name: "ReverseBunkerVbins",
  inject: ["apiUrl", "crud"],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      customUrl: `${this.apiUrl}/grower`,
      getListUrl: "",
      visibleModal: false,
      recordItem: this.record,
      selectedRowKeys: [],
      loading: false
    };
  },
  watch: {
    visible(newVal) {
      this.selectedRowKeys = [];
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
      this.getListUrl = "";
      this.getListUrl = `${this.apiUrl}/grower/bunkers/vbins/${this.recordItem.id}/reversal`;
      this.fetchList(this.getListUrl);
    }
  },
  methods: {
    fetchList(customUrl) {
      this.crud.fetchList(customUrl, void 0, false);
    },
    storeValue(value, resourceKey) {
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
      return;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    reverseItem() {
      this.loading = true;
      this.storeValue(this.selectedRowKeys, "listIds");
      this.crud.submitEntity("replace", `${this.getListUrl}`).then(() => {
        this.loading = false;
        this.$emit("updated", { type: "reverse", visible: false });
        this.recordItem = null;
      });
    },
    cancel() {
      this.$emit("update:visible", false);
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, "a9d00268", null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ReverseBunkerVbins$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "vbins-reverse-container"
  }, [_c("resource", {
    attrs: {
      "name": "grower.vbins.reverse",
      "api-url": _vm.apiUrl
    }
  }, [_c("reverse-bunker-vbins", {
    attrs: {
      "visible": _vm.visibleModal,
      "record": _vm.recordItem
    },
    on: {
      "update:visible": [function($event) {
        _vm.visibleModal = $event;
      }, _vm.updateVisible],
      "update:record": function($event) {
        _vm.recordItem = $event;
      },
      "updated": function($event) {
        return _vm.updated($event);
      }
    }
  })], 1), _c("router-view")], 1);
};
var staticRenderFns$2 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: {
    ReverseBunkerVbins: ReverseBunkerVbins$1
  },
  props: {
    record: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$1,
      visibleModal: this.visible,
      recordItem: this.record
    };
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
    }
  },
  methods: {
    updated(e) {
      this.$emit("updated", e);
    },
    updateVisible(e) {
      this.$emit("update:visible", e);
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ReverseBunkerVbins = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "bunker-vbins"
  }, [_vm.tableRendered ? _c("list-composer", {
    attrs: {
      "title": "VBins Processing",
      "has-active": false,
      "has-filter-inactive": false,
      "actions-width": 110,
      "search-by": "BunkerCode, ProduceSize",
      "ph-search-by": "Search by Bunker, Produce Size"
    },
    scopedSlots: _vm._u([{
      key: "customFilter",
      fn: function() {
        return [_c("div", {
          staticClass: "status-filter"
        }, [_c("select-filter", {
          attrs: {
            "source": "value",
            "source-label": "text",
            "data-source": _vm.filterOptions,
            "value": _vm.filterOptions[0]
          },
          on: {
            "change": _vm.changeStatus
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "action",
      fn: function(_ref) {
        var record = _ref.record;
        return [_vm.hasReceiptPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Receipt")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "btn-bunker",
          attrs: {
            "icon": "file-text",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.changeVisibleModal("receipt", record);
            }
          }
        })], 1) : _vm._e(), _vm.hasTipPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Tip")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "btn-bunker",
          attrs: {
            "icon": "download",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.changeVisibleModal("tip", record);
            }
          }
        })], 1) : _vm._e(), _vm.hasReversePermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Reverse")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "btn-bunker",
          attrs: {
            "icon": "undo",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.changeVisibleModal("reverse", record);
            }
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 482796716)
  }, [_c("text-field", {
    key: "BunkerCode",
    attrs: {
      "data-index": "bunkerCode",
      "parse": _vm.parseBunkerCode,
      "title": "Bunker",
      "sorter": true
    }
  }), _c("text-field", {
    key: "ProduceSize",
    attrs: {
      "data-index": "produceSize",
      "title": "Produce Size"
    }
  }), _c("text-field", {
    key: "CurrentWeight",
    attrs: {
      "data-index": "currentWeight",
      "title": "Current Weight"
    }
  }), _c("tag-field", {
    key: "Status",
    attrs: {
      "data-index": "status",
      "title": "Status",
      "color": _vm.getStatusColor,
      "parse": _vm.parseStatus
    }
  })], 1) : _vm._e(), _vm.hasReceiptPermission ? _c("receipt-bunker-vbins", {
    attrs: {
      "visible": _vm.visibleReceipt,
      "record": _vm.record
    },
    on: {
      "update:visible": function($event) {
        _vm.visibleReceipt = $event;
      },
      "update:record": function($event) {
        _vm.record = $event;
      },
      "updated": _vm.onUpdated
    }
  }) : _vm._e(), _vm.hasTipPermission ? _c("tips-bunker-vbins", {
    attrs: {
      "visible": _vm.visibleTip,
      "record": _vm.record
    },
    on: {
      "update:visible": function($event) {
        _vm.visibleTip = $event;
      },
      "update:record": function($event) {
        _vm.record = $event;
      },
      "updated": _vm.onUpdated
    }
  }) : _vm._e(), _vm.hasReversePermission ? _c("reverse-bunker-vbins", {
    attrs: {
      "visible": _vm.visibleReverse,
      "record": _vm.record
    },
    on: {
      "update:visible": function($event) {
        _vm.visibleReverse = $event;
      },
      "update:record": function($event) {
        _vm.record = $event;
      },
      "updated": _vm.onUpdated
    }
  }) : _vm._e()], 1);
};
var staticRenderFns$1 = [];
var ListBunkerVbins_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "ListBunkerVbins",
  components: {
    ReceiptBunkerVbins,
    TipsBunkerVbins,
    ReverseBunkerVbins
  },
  inject: ["resourceName", "crud", "can"],
  data() {
    return {
      field: "Status",
      filterOptions: [
        {
          value: "all",
          text: "All Status",
          color: "black"
        },
        {
          value: "OPEN"
        },
        {
          value: "HOLD"
        },
        {
          value: "CLOSED"
        },
        {
          value: "LOCKED"
        }
      ],
      visibleReceipt: false,
      visibleReverse: false,
      visibleTip: false,
      record: null,
      tableRendered: true
    };
  },
  computed: {
    hasReceiptPermission() {
      return this.can(RECEIPT_PERMISSION);
    },
    hasTipPermission() {
      return this.can(TIP_PERMISSION);
    },
    hasReversePermission() {
      return this.can(REVERSE_PERMISSION);
    }
  },
  created() {
    this.filterOptions.filter((key) => key.value !== "all").map((key) => {
      Object.assign(key, {
        text: BUNKER_STATUS_TEXTS[key.value],
        color: BUNKER_STATUS_COLORS[key.value]
      });
    });
    this.crud.clearFilters();
    const { count, pageSize } = this.crud.getPagination();
    this.crud.setPagination({ count, page: 1, pageSize });
    this.crud.deleteFilter("IsInactive");
    this.fetchList();
  },
  methods: {
    getStatusColor(value) {
      return BUNKER_STATUS_COLORS[value];
    },
    parseStatus(value) {
      return BUNKER_STATUS_TEXTS[value];
    },
    parseBunkerCode(value, option) {
      return `${value} ${option.id}`;
    },
    fetchList(isChangeStt = true) {
      if (isChangeStt) {
        this.crud.fetchList();
      } else
        this.crud.fetchList().then(() => {
          this.tableRendered = true;
        });
    },
    changeStatus(status) {
      if (status.value == "all")
        this.crud.deleteFilter(this.field);
      else {
        const { count, pageSize } = this.crud.getPagination();
        this.crud.setPagination({ count, page: 1, pageSize });
        this.crud.setFilter(this.field, status);
      }
      this.fetchList(true);
    },
    changeVisibleModal(type, record) {
      this.record = record;
      switch (type) {
        case "receipt":
          this.visibleReceipt = !this.visibleReceipt;
          break;
        case "tip":
          this.visibleTip = !this.visibleTip;
          break;
        case "reverse":
          this.visibleReverse = !this.visibleReverse;
          break;
      }
    },
    onUpdated(e) {
      this.tableRendered = false;
      this.fetchList(false);
      this.changeVisibleModal(e.type, null);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "77b6ef36", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListBunkerVbins = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-bunker-vbins"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.bunkers.vbins",
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("list-bunker-vbins")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListBunkerVbins
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_VBINSPROCESSING,
      itemsMenu: [
        {
          key: "bunkers",
          title: "Bunker Processing",
          path: ""
        },
        {
          key: "bunkervbins",
          title: "VBins Processing",
          path: "/watties-grower/bunker-vbins"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
